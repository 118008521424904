import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Theme from "../../Theme";
import Modal from "../../Modal";
import Dropdown from "../../Dropdown";
import {
  createBuyerUser,
  verifyBuyerAddress,
} from "../../../services/ApiServices";
import TextInput from "../../TextInput";
import database from "../../../services/firebaseServices";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { ethers } from "ethers";
import { getUser, ModalClose, ModalOpen } from "../../../redux";
import magicLogo from "../../../assets/wallet_icons/magic.svg";
import walletconnectLogo from "../../../assets/wallet_icons/walletconnect.svg";
//Connect wallet lib import
import WalletConnectProvider from "@walletconnect/web3-provider";
//walletconnect support libs
import { config } from "../../../config";
import Accounticon from "../../../components/img/AccountIcon.png";
import { useSnackbar } from "notistack";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillPencilFill } from "react-icons/bs";
import TextInput1 from "../../TextInput1";
import Register from "../../../screens/Authentication/Register";
import Login from "../../../screens/Authentication/Login";
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import { TiShoppingCart } from "react-icons/ti";
import {
  getDatabase,
  set,
  ref,
  child,
  push,
  update,
  onValue,
  get,
} from "firebase/database";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { useWeb3React } from "@web3-react/core";
import { useActiveWeb3React } from "../../../hooks/index.ts";
import { toDisplay } from "../../../utils/helpers.ts";
import { minifyAddress } from "../../../utils/helpers";
import firebase from "firebase/compat";
import Polygon from "../../../images/polygon.png";
import { Popover, Spin } from "antd";
import "antd/dist/antd.css";

const customNodeOptions = {
  rpcUrl: config[config.currentNetwork()].rpcURL, // Polygon RPC URL
  chainId: config[config.currentNetwork()].chainId, // Polygon chain id
};

// const items = [
//   {
//     title: "My profile",
//     icon: "user",
//     url: "/profile/" + uid,
//   },
//   {
//     title: "My items",
//     icon: "image",
//     url: "/Explore",
//   },
//   {
//     title: "Dark theme",
//     icon: "bulb",
//   },
//   {
//     title: "Disconnect",
//     icon: "exit",
//   },
// ];

const options = ["MetaMask"];

const User = (props, { className, refresh, stick, connect, logout }) => {
  const myRef = useRef();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isSelectWallet, setIsSelectWallet] = useState(false);
  const [wallet, setWallet] = useState(options[0]);
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [errormsg, setErrormsg] = useState();
  const [isCreate, setIsCreate] = useState(false);
  const [isMagic, setIsMagic] = useState(false);
  const [name, setName] = useState(localStorage.getItem("userName"));
  const [premiumnft, setPremiumnft] = useState("");
  const [nftAddress, setNftAddress] = useState(
    localStorage.getItem("nftAddress")
  );
  const [uid, setUid] = useState("");
  const [isLoading, loading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [isModalLogin, setIsModalLogin] = useState(false);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [username, setUsername] = useState(localStorage.getItem("name"));
  const [emailId, setEmailId] = useState(localStorage.getItem("email"));
  const [visible1, setVisible1] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordError1, setPasswordError1] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [error1, setError1] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordShown, setPasswordshown] = useState(false);
  const [passwordShown1, setPasswordshown1] = useState(false);
  const [mobNo, setMobNo] = useState(0);
  const [mobError, setMobError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError1, setEmailError1] = useState("");
  const [getName, setGetName] = useState(null);
  const { library, chainId } = useActiveWeb3React();
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [loadings, setLoadings] = useState(false);
  const [userLastName, setUserLastName] = useState(
    localStorage.getItem("lastName")
  );
  const [mobileNumber, setMobileNumber] = useState(
    localStorage.getItem("mobileNo")
  );
  const [copy, setCopy] = useState("");
  const [verified, setVerified] = useState("");
  const [location, setLocation] = useState("");
  const [open, setOpen] = useState(false);
  let history = useHistory();
  const auth = getAuth();

  useEffect(() => {
    let prevLocation;

    history.listen((nextLocation) => {
      //....
      prevLocation = nextLocation;
      if (prevLocation?.pathname.includes("/collectionDetail/items/"))
        setLocation(prevLocation?.pathname);
    });
  }, [history]);
  // console.log(location,"location")
  const getBalance = useCallback(async () => {
    const balance = await library?.getBalance(account);
    if (typeof balance === "undefined") {
      return;
    }

    setBalance(toDisplay(balance));
  }, [account, library]);

  useEffect(() => {
    if (active) {
      getBalance();
    }
  }, [active, getBalance, library]);

  const items = [
    // {
    //   title: "My items",
    //   icon: "image",
    //   url: "/Explore",
    // },
    // {
    //   title: "Dark theme",
    //   icon: "bulb",
    // },
    {
      title: "Disconnect",
      icon: "exit",
    },
  ];
  const items1 = [
    {
      title: "My profile",
      icon: "user",
      url: "/profile/:uid" + user.id,
    },
    {
      title: "Sign Out",
      icon: "exit",
    },
  ];

  const emailFunction = async (e) => {
    setErrors("");
    setEmailError1("");
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmail("");
      setEmailError1("Please enter the valid Email");
    } else {
      setEmailError1("");
      setEmail(e.target.value);
    }
  };
  const passwordFunction = (e) => {
    setErrors("");
    if (e.target.value.length < 8)
      setPasswordError1("Password Should be 8 characters");
    else {
      setErrors("");
      setPasswordError1("");
      setPassword(e.target.value);
    }
  };

  const login = () => {
    setLoadings(true);
    if (!email) {
      setLoadings(false);
      setError1("Email is required!");
    } else if (!password) {
      setLoadings(false);
      setError1("Password is required!");
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          getData(user.uid);
          localStorage.setItem("email", user.email);
          localStorage.setItem("uid", user.uid);
          writeUserDatas(user.uid);
          if (location.includes("/collectionDetail/items/")) {
            history.push(location);
          } else history.push("/");
          setIsModalLogin(false);
          setLoadings(false);
          setError1("");
          setEmail("");
          setPassword("");
        })
        .catch((err) => {
          setLoadings(false);
          if (err.code === "auth/user-not-found")
            setError1("User Not Found please register");
          else if (err.code === "auth/wrong-password")
            setError1("Invalid Password");
          console.log(err);
          // setEmail("");
          // setPassword("");
        });
    }
  };
  // console.log(email,password)
  function writeUserDatas(uid) {
    const db = getDatabase();
    update(ref(db, process.env.REACT_APP_FIREBASE_URL + uid), {
      address: account,
    })
      .then(() => {
        console.log(user, "account");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const firstNameFunction = (e) => {
    setErrors("");
    setFirstNameError("");
    setFirstName("");
    if (e.target.value.length < 3) {
      setFirstName("");
      setFirstNameError("First name should be atleast 3 characters");
    } else {
      setErrors("");
      setFirstNameError("");
      setFirstName(e.target.value);
    }
  };
  const lastNameFunction = (e) => {
    setErrors("");
    setLastNameError("");
    setLastName("");
    if (e.target.value.length < 3) {
      setLastName("");
      setLastNameError("Last name should be atleast 3 characters");
    } else {
      setErrors("");
      setLastNameError("");
      setLastName(e.target.value);
    }
  };
  const emailFunctions = async (e) => {
    setErrors("");
    setEmailError("");
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmail("");
      setEmailError("Please enter the valid email");
    } else {
      setEmailError("");
      setEmail(e.target.value);
    }
  };
  const mobileFunction = (e) => {
    setErrors("");
    setMobError("");
    const re = /^[\+\d]?(?:[\d-.\s()]*)$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setMobNo("");
      setMobError("Please enter the valid mobile number");
    } else {
      setErrors("");
      setMobError("");
      setMobNo(e.target.value);
    }
  };
  const passwordFunctions = (e) => {
    setErrors("");
    setPasswordError("");
    if (e.target.value.length < 8) {
      setPassword("");
      setPasswordError("Password should be 8 characters");
    } else {
      setErrors("");
      setPasswordError("");
      setPassword(e.target.value);
    }
  };
  const confirmPasswordFunction = (e) => {
    setErrors("");
    setConfirmPasswordError("");
    if (password !== e.target.value) {
      setConfirmPassword("");
      setConfirmPasswordError("Password is incorrect");
    } else {
      setErrors("");
      setConfirmPasswordError("");
      setConfirmPassword(e.target.value);
    }
  };
  // console.log(process.env.REACT_APP_FIREBASE_URL)
  const createAccount = async (id) => {
    setLoadings(true);
    if (!firstName) {
      setLoadings(false);
      setErrors("First name is required!");
    } else if (!lastName) {
      setErrors("Last name is required!");
      setLoadings(false);
    } else if (!email) {
      setErrors("Email is required!");
      setLoadings(false);
    } else if (!mobNo) {
      setErrors("Mobile number is required!");
      setLoadings(false);
    } else if (!password) {
      setErrors("Password is required!");
      setLoadings(false);
    } else if (!confirmPassword) {
      setErrors("Confirm password is required!");
      setLoadings(false);
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          console.log(userCredential);
          const user = userCredential.user;
          console.log(user.metadata.creationTime);
          // setEmailId(user.email);
          // setUid(user.uid);
          console.log(uid);
          localStorage.setItem("email", user.email);
          localStorage.setItem("uid", user.uid);
          if (location.includes("/collectionDetail/items/")) {
            console.log("success");
            history.push(location);
          } else history.push("/");
          // localStorage.setItem("registerDate", user.metadata.creationTime);
          writeUserData(user.uid);
          getData(user.uid);
          setRegisterModal(false);
          setLoadings(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobNo("");
          setPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          setLoadings(false);
          if (err.code === "auth/email-already-in-use")
            setErrors("Account aleady exist please Login");

          console.log(err);
        });
    }
  };
  function writeUserData(uid) {
    const db = getDatabase();
    set(ref(db, process.env.REACT_APP_FIREBASE_URL + uid), {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: mobNo,
      address: account,
      email: email,
      premiumnft: [],
      kycStatus: "Pending Verification",
    })
      .then((user) => {
        console.log(user, "userDetails");
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log(user);
    // console.log(db, firstName, lastName, mobNo, confirmPassword);
  }

  const errorCancel = () => {
    console.log("error cancelled");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setMobError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setEmailError1("");
    setPasswordError1("");
    setErrors("");
    setError1("");
  };
  const db = getDatabase();
  const getData = (uid) => {
    const userId = auth.currentUser.uid;
    return onValue(
      ref(db, process.env.REACT_APP_FIREBASE_URL + userId),
      (snapshot) => {
        const username =
          (snapshot.val() && snapshot.val().firstName) || "username";
        // console.log(username, "uname")
        setUsername(username);
        const lastname =
          (snapshot.val() && snapshot.val().lastName) || "username";
        setUserLastName(lastname);
        const mobileNumber =
          (snapshot.val() && snapshot.val().phoneNumber) || "username";
        // const kyc =
        //   (snapshot.val() && snapshot.val().kycStatus) || "Pending Verification";
        // setVerified(kyc)
        localStorage.setItem("mobileNo", mobileNumber);
        localStorage.setItem("name", username);
        localStorage.setItem("lastName", userLastName);
        // localStorage.setItem("kycStatus", kyc)
      },
      {
        onlyOnce: true,
      }
    );
  };

  useEffect(() => {
    // let email = localStorage.getItem("email");
    // setEmail(email);
    let address = localStorage.getItem("nftAddress");
    setNftAddress(address);
    // let name = localStorage.getItem("name");
    // setUsername(name);
    // let emails = localStorage.getItem("email");
    // setEmailId(emails);
    let uid = localStorage.getItem("uid");
    setUid(uid);
  }, [username, emailId, uid, getName]);

  const handleUserName = (value) => {
    setUserNameError("");
    if (value.length < 3) {
      setUserNameError("Please enter username with atleast 3 characters");
    } else {
      setUserNameError("");
      setUserName(value);
    }
  };

  const handleEmail = (value) => {
    setEmailError("");
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.length <= 0) {
      setEmailError("Please enter Email");
    } else if (!re.test(String(value).toLowerCase())) {
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(value);
    }
  };
  const register = async () => {
    if (!userName) {
      setEmailError("User Name Required");
    } else if (!email) {
      setEmailError("Email Required");
    } else {
      let result, body;
      body = {
        address: address,
        userName: userName,
        email: email,
      };
      console.log("req body", body);
      setIsCreate(false);
      setIsSelectWallet(false);
      setIsLogin(false);
      setName(userName);
      setNftAddress(address);
      localStorage.setItem("userName", userName);
      localStorage.setItem("nftAddress", address);
      return;
      try {
        result = await createBuyerUser(body);
      } catch (error) {
        console.log("create user error", error);
        setErrormsg(error.message);
        return;
      }
      console.log("Result", result);
      if (result.success) {
        console.log(result);
        setIsCreate(false);
        setIsSelectWallet(false);
        setIsLogin(false);
        setName(result.user.userName);
        setNftAddress(result.user.address);
        setUid(result.user.id);
        localStorage.setItem("userName", result.user.userName);
        localStorage.setItem("nftAddress", result.user.address);
        localStorage.setItem("uid", result.user.id);
      } else {
        setErrormsg("Something went wrong please try again later.");
      }
    }
  };
  const logOut = () => {
    console.log("logout");
    setNftAddress("");
    setAddress("");
    localStorage.removeItem("nftAddress");
    setVisible(false);
  };

  const signout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // signOut(auth.currentUser).then(function () {
        console.log("signout");
        setUid("");
        setUsername("");
        setUserLastName("");
        setEmailId("");
        setUserLastName("");
        setVerified("");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("uid");
        localStorage.removeItem("nftAddress");
        localStorage.removeItem("lastName");
        localStorage.removeItem("kycStatus");
        localStorage.removeItem("mobileNo");
        localStorage.removeItem("registerDate");
        setVisible1(false);
        history.push("/");
      })
      .catch(function (error) {
        console.log(error);
      });
    // setGetName(null);
  };

  const WalletBalance = ({ id }) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provider.getBalance(id).then((balance) => {
      let value = parseFloat(ethers.utils.formatEther(balance));
      setBalance(value);
    });

    return <div className={styles.price}>{balance.toFixed(4)}</div>;
  };
  const walletConnect = () => {
    props.connect();
    setIsSelectWallet(false);
  };
  const connectwallet = async () => {
    let meta;
    try {
      if (!window.ethereum) {
        setIsSelectWallet(false);
        // return setIswalletAvailable(false);
        // throw setIswalletAvailable(false);
      }

      // meta = await window.ethereum.send("eth_requestAccounts");
      // activate(injected);
      setIsSelectWallet(false);
      if (account) {
        setAddress(account);
        setIsSelectWallet(false);
      } else setErrormsg(error);
    } catch (err) {
      setIsSelectWallet(false);
      return setErrormsg(err.message);
    }

    setErrormsg("");

    // if(!initial) purchase();
  };
  useEffect(() => {
    setLoadings(true);
    const user = auth.currentUser;
    setLoadings(true);
    if (user !== null) {
      getData(user?.uid);
      setLoadings(false);
      // setEmailId(user?.email);
      setUid(user?.uid);
      let emails = localStorage.getItem("email");
      setEmailId(emails);
      return onValue(
        ref(db, process.env.REACT_APP_FIREBASE_URL + user?.uid),
        (snapshot) => {
          const username =
            (snapshot.val() && snapshot.val().firstName) || "username";
          const lastname =
            (snapshot.val() && snapshot.val().lastName) || "username";
          setUserLastName(lastname);
          const mobileNumber =
            (snapshot.val() && snapshot.val().phoneNumber) || "username";
          setMobileNumber(mobileNumber);
          // const kyc =
          //   (snapshot.val() && snapshot.val().kycStatus) || "Pending Verification";
          // setVerified(kyc)
          localStorage.setItem("name", username);
          localStorage.setItem("lastName", userLastName);
          localStorage.setItem("mobileNo", mobileNumber);
          // localStorage.setItem("kycStatus", kyc)
          // console.log(lastname)
          localStorage.setItem(
            "registerDate",
            auth?.currentUser.metadata.creationTime
          );
          // console.log(snapshot.val())
        },
        {
          onlyOnce: true,
        }
      );
    } else return setLoadings(false);
  }, [
    username,
    emailId,
    userLastName,
    auth?.currentUser,
    mobileNumber,
    location,
  ]);
  // console.log(verified,"verified")

  function myFunction() {
    return enqueueSnackbar("Address copied to clipboard", {
      variant: "success",
    });
  }

  function copyToClipboard() {
    if (navigator.clipboard && window.isSecureContext) {
      console.log("copied");
      myFunction();
      return navigator.clipboard.writeText(account);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = account;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      console.log("error");
      myFunction();
      return new Promise((res, rej) => {
        console.log("error in console");
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }
  const kycVerify = () => {
    const userId = auth?.currentUser?.uid || uid;
    return onValue(
      ref(db, process.env.REACT_APP_FIREBASE_URL + userId),
      (snapshot) => {
        const kyc =
          (snapshot.val() && snapshot.val().kycStatus) ||
          "Pending Verification";
        setVerified(kyc);
        // console.log(kyc)
      },
      {
        onlyOnce: true,
      }
    );
  };
  //   const hide = () => {
  //     setOpen(false);
  //     console.log("not open",open);
  //   };
  // const handleOpenChange = (newOpen) => {
  //     setOpen(newOpen);
  //     console.log(" open",open);

  //   };
  const contentDIV = (
    <div>
      This is test site, so you are not able to connect with buy matic{" "}
      <a
        href="https://faucet.polygon.technology/"
        target="_blank"
        rel="noreferrer"
      >
        click here{" "}
      </a>
    </div>
  );
  useEffect(() => {
    if (auth?.currentUser?.uid || uid) {
      kycVerify();
    }
  }, [auth?.currentUser?.uid, verified, uid, db, open]);

  return isLoading ? (
    <Loader className={styles.loader} />
  ) : (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
        setVisible1(false);
      }}
    >
      <div className={styles.loginFlex}>
        {active && (
          <div>
            {loadings ? (
              <Loader className={styles.loader} />
            ) : (
              <>
                {emailId ? null : (
                  <button
                    className={cn("button", styles.button1)}
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                  >
                    Sign In
                  </button>
                )}
              </>
            )}
          </div>
        )}
        {active ? (
          <div>
            <div className={cn(styles.user, className)}>
              {/* {name ? ( */}
              <div>
                <div
                  className={cn("button", styles.button)}
                  onClick={() => {
                    setVisible(!visible);
                    kycVerify();
                  }}
                >
                  {minifyAddress(account, 6)}
                  {/* {nftAddress
                    ? nftAddress.toString().slice(0, 5) +
                      "...." +
                      nftAddress.toString().slice(-7)
                    : "Connect Wallet"} */}
                </div>
                {/* <img
                  className={styles.avatar}
                  src="https://www.freeiconspng.com/thumbs/person-icon-blue/person-icon-blue-18.png"
                  alt="Avatar"
                  onClick={() => setVisible(!visible)}
                /> */}
              </div>

              {/* <button
            className={cn("button", styles.button)} 
            onClick={() => {
              setIsSelectWallet(true);
            }}
          >
            Connect Wallet
          </button> */}
              <OutsideClickHandler
                onOutsideClick={() => {
                  setVisible(false);
                }}
              >
                {visible && (
                  <div className={styles.body}>
                    <div className={styles.name}></div>
                    {active && (
                      <div className={styles.code}>
                        <div className={styles.number}>
                          {minifyAddress(account, 8)}
                          {/* {nftAddress.slice(0, 9) +
                          "...." +
                          nftAddress.toString().slice(-9)} */}
                        </div>
                        <button
                          className={styles.copy}
                          onClick={() => {
                            copyToClipboard();
                          }}
                        >
                          <Icon name="copy" size="16" />
                        </button>
                      </div>
                    )}
                    <div className={styles.userimg}>
                      {/* {emailId &&
                         <img
                        className={styles.avatar1}
                        src="https://www.freeiconspng.com/thumbs/person-icon-blue/person-icon-blue-18.png"
                        alt="Avatar"
                        onClick={() => setVisible1(!visible1)}
                      />} */}
                      <div className={styles.profileName}>
                        {/* {username?.length > 20
                        ? username?.toString().slice(0, 20) + "...."
                        : username} */}
                        {username === "username" ? emailId : username}{" "}
                        {userLastName === "username" ? null : userLastName}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      {active && (
                        <div className={styles.line}>
                          <img
                            className={styles.Accountimagedesign}
                            src={
                              chainId === 80001
                                ? Polygon
                                : "/images/content/etherium-circle.jpg"
                            }
                            alt="Etherium"
                          />

                          <div>
                            Balance
                            <div className={styles.price}>{balance}</div>
                          </div>

                          {/* <div>Balance
                            <WalletBalance id={nftAddress} />
                          </div> */}
                        </div>
                      )}
                      {emailId && (
                        <div className={styles.buyLine}>
                          <TiShoppingCart />
                          {/* <Popover
                            placement="top"
                            overlayStyle={{ top: "30px !important" }}
                            content={<div>{contentDIV}</div>}
                            trigger="click"
                            open={open}
                            // onOpenChange={handleOpenChange}
                          > */}
                          <button
                            //  to={
                            //   process.env.REACT_APP_ONMETA_URL && verified === "Verified" ? '/buyMatic' : null
                            // }
                            onClick={() => {
                              kycVerify();
                              setVisible(false);
                              if (!verified) {
                                return null;
                              } else if (verified == "Pending Verification") {
                                console.log("no kyc");
                                history.push("/profile/" + account);
                                return enqueueSnackbar(
                                  "Please complete your KYC process.",
                                  { variant: "error" }
                                );
                              } else if (process.env.REACT_APP_ONMETA_URL) {
                                // console.log("meta url")
                                history.push("/buyMatic");
                              }
                              // https://faucet.polygon.technology/
                              else {
                                setOpen(true);
                                console.log("open");
                              }
                              // enqueueSnackbar ('This is test site, so you are not able to connect with buy matic',{variant:"error"})
                            }}
                          >
                            <h5>Buy MATIC</h5>
                          </button>
                          {/* </Popover> */}
                        </div>
                      )}
                      <div>
                        {emailId && (
                          <Link
                            to={"/profile/" + account}
                            onClick={() => {
                              setVisible1(!visible1);
                              setVisible(false);
                            }}
                          >
                            <div className={styles.line12}>
                              <div className={styles.profileNameSlice}>
                                {username === "username"
                                  ? emailId?.slice(0, 1)
                                  : username?.slice(0, 1)}
                              </div>
                              {/* <img
                                className={styles.Accountimagedesign}
                                src={Accounticon}
                                alt=""
                              /> */}
                              <div className={styles.line_2}>My Account</div>
                            </div>
                          </Link>
                        )}
                      </div>
                      {/* <Link to={"/profile/:uid"} onClick={() => setVisible(!visible)}>  
                    <div className={styles.line12}>
                    <img className={styles.Accountimagedesign} src={Accounticon} alt=""/>
                    <div>My Account</div>
                    </div>
                    </Link> */}
                    </div>
                    {/* {nftAddress&&
                <button
                  className={cn("button", styles.button)}
                  onClick={() => {
                    setIsSelectWallet(true);
                  }}
                >
                  Connect Wallet
                </button>} */}

                    {/* <button
                      className={cn("button", styles.button)}
                    > */}

                    {/* </button> */}

                    {/* <Divider /> */}

                    <div className={styles.line123}></div>

                    <div className={styles.menu}>
                      {items.map((x, index) =>
                        x.url ? (
                          x.url.startsWith("http") ? (
                            <a
                              className={styles.item}
                              href={x.url}
                              rel="noopener noreferrer"
                              key={index}
                            >
                              <div className={styles.icon}>
                                <Icon name={x.icon} size="20" />
                              </div>
                              <div className={styles.text}>{x.title}</div>
                            </a>
                          ) : (
                            user.id && (
                              <Link
                                className={styles.item}
                                to={x.url}
                                onClick={() => setVisible(!visible)}
                                key={index}
                              >
                                <div className={styles.icon}>
                                  <Icon name={x.icon} size="20" />
                                </div>
                                <div className={styles.text}>
                                  {x.title}
                                  {}
                                </div>
                              </Link>
                            )
                          )
                        ) : x.title == "Disconnect" ? (
                          <div
                            className={styles.item}
                            key={index}
                            onClick={() => {
                              props.logout();
                              signout();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <div className={styles.icon}>
                              <Icon name={x.icon} size="20" />
                            </div>
                            <div className={styles.text}>{x.title}</div>
                          </div>
                        ) : (
                          <div className={styles.item} key={index}>
                            <div className={styles.icon}>
                              <Icon name={x.icon} size="20" />
                            </div>
                            <div className={styles.text}>{x.title}</div>
                            <Theme className={styles.theme} />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpen(false);
              }}
            >
              {open && (
                <div className={styles.popover}>
                  This is test site, so you are not able to connect with buy
                  matic. &nbsp;
                  <a
                    href="https://faucet.polygon.technology/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => setOpen(false)}
                  >
                    Click here
                  </a>
                </div>
              )}
            </OutsideClickHandler>
          </div>
        ) : (
          <div className={cn(styles.user, className)}>
            <button
              className={cn("button", styles.button)}
              onClick={() => {
                setIsSelectWallet(true);
              }}
            >
              Connect Wallet
            </button>
            <img
              className={styles.avatar}
              src="https://icon-library.com/images/person-icon-red/person-icon-red-14.jpg"
              alt="Avatar"
              onClick={() => {
                setIsSelectWallet(true);
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={isSelectWallet}
        onClose={() => setIsSelectWallet(false)}
        onOutsideClick={() => setIsSelectWallet(false)}
      >
        {isCreate ? (
          <div>
            <h3 className="text-center">Tell Us About Yourself</h3>
            <div className={styles.register}>
              <TextInput
                className={styles.field}
                label="User Name"
                name="userName"
                type="text"
                onChange={(e) => handleUserName(e.target.value)}
                placeholder="Enter name"
              />
              {userNameError && (
                <div className={styles.alert}>{userNameError}</div>
              )}
              <TextInput
                className={styles.field}
                label="Email"
                name="email"
                type="email"
                onChange={(e) => handleEmail(e.target.value)}
                placeholder="Enter email"
              />
              {emailError && (
                <div className={`${styles.alert} text-center`}>
                  {emailError}
                </div>
              )}
            </div>
            {errormsg && <div className={styles.alert}>{errormsg}</div>}
            <div className={styles.btns}>
              <button
                className={cn("button", styles.button)}
                onClick={() => register()}
              >
                Save
              </button>
              <button
                className={cn("button-stroke", styles.button)}
                onClick={() => {
                  setIsCreate(false);
                  setIsSelectWallet(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : isMagic ? (
          <div>
            <h3 className="text-center">Login</h3>

            {/* <div className={styles.btns}>
                <button
                  className={cn("button", styles.button)}
                  onClick={() => connectWallet("magic", email)}
                >
                  continue
                </button>
                <button
                  className={cn("button-stroke", styles.button)}
                  onClick={() => {
                    setIsMagic(false);
                    setIsSelectWallet(true)
                  }}
                >
                  Cancel
                </button>
              </div> */}
          </div>
        ) : (
          <div className={styles.modal}>
            <div className={cn("h4")}>Connect Wallet</div>
            <br />
            <div>
              Please connect your wallet to continue.
              <br /> The system supports the following wallets
              <br />
              <br />
            </div>
            <div>
              {errormsg && (
                <div className="error text-center"> {errormsg} </div>
              )}
            </div>
            <div
              className={styles.wallet}
              onClick={() =>
                // connectWallet("metamask")
                // props.connect()
                // connectwallet()
                walletConnect()
              }
            >
              <img
                className={styles.metamask}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png"
              />
              <div className={styles.btnContent}>
                <span className={styles.left1}>MetaMask</span>
              </div>
              <img
                className={styles.iconimg1}
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjAyMTUgOC42NDVMMTUuMzY0OCAxNEwxMC4wMjE1IDE5LjM1NUwxMS42NjY1IDIxTDE4LjY2NjUgMTRMMTEuNjY2NSA3TDEwLjAyMTUgOC42NDVaIiBmaWxsPSIjNzc3RTkwIi8+Cjwvc3ZnPgo="
              />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        visible={isModalVisible}
        onClose={() => {
          errorCancel();
          setIsModalVisible(false);
        }}
        onOutsideClick={() => setIsModalVisible(false)}
      >
        <div className={styles.connectcontainer}>
          <div className={styles.header}>
            <h4 className={styles.headname}>Sign In / Register</h4>
            <p className="">Choose on option</p>
          </div>
          <div className={styles.connectflex}>
            <button
              className={styles.signin}
              onClick={() => {
                setIsModalLogin(true);
                setIsModalVisible(false);
              }}
            >
              <div>
                <AiOutlineMail />
                <p className={styles.name}>
                  Sign In With <br /> Email
                </p>
              </div>
            </button>
            <button
              className={styles.register}
              onClick={() => {
                setRegisterModal(true);
                setIsModalVisible(false);
              }}
            >
              <div>
                <BsFillPencilFill />
                <p className={styles.name}>
                  Register New <br /> Account
                </p>
              </div>
            </button>
          </div>
          <p className={styles.header}>
          On clicking "Sign In" or "Register" you agree to wowTalkies&nbsp;
            <a
              href="https://wowt-termsandconditions-policy.s3.amazonaws.com/tandc/wowTtermsandcondition.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
            .
          </p>
        </div>
      </Modal>
      <Modal
        visible={isModalLogin}
        onClose={() => {
          errorCancel();
          setIsModalLogin(false);
        }}
        onOutsideClick={() => setIsModalLogin(false)}
      >
        {/* <Login onClose={()=> setIsModalLogin(false)} visible={() => {setIsModalLogin(false);setRegisterModal(true)}}/> */}
        <div className={styles.loginDiv}>
          <h3 className={styles.header1}>Login With Email</h3>
          <TextInput1
            className={styles.registerInput}
            autofocus
            label="Email"
            name="email"
            type="email"
            autoFocus={true}
            placeholder="Enter email"
            onChange={(e) => {
              emailFunction(e);
            }}
          />
          {emailError1 && <div className={styles.alert}>{emailError1}</div>}
          {/* <div className={styles.passwordShow2}> */}
          <TextInput1
            className={styles.registerInput}
            label="Password"
            name="password"
            type={passwordShown ? "text" : "password"}
            placeholder="Enter password"
            onKeyPress={(e) => e.key === "Enter" && login()}
            onChange={(e) => {
              passwordFunction(e);
            }}
          />
          <div className={styles.passwordShow2}>
            {passwordShown ? (
              <BsFillEyeFill
                onClick={() => {
                  setPasswordshown(false);
                }}
              />
            ) : (
              <BsFillEyeSlashFill
                onClick={() => {
                  setPasswordshown(true);
                }}
              />
            )}
          </div>
          {passwordError1 && (
            <div className={styles.alert}>{passwordError1}</div>
          )}
          {/* </div> */}
          {error1 && <div className={styles.alert}>{error1}</div>}
          <div className={styles.loginTxt}>
            If you don't have an account? Please register{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                setIsModalLogin(false);
                setRegisterModal(true);
              }}
            >
              here
            </span>
          </div>
          <div className={styles.buttons}>
            <button
              className={cn("button", styles.cancelbutton)}
              onClick={() => login()}
            >
              Login
              {loadings && <Spin size="small" />}
            </button>
            <button
              className={cn("button", styles.cancelbutton)}
              onClick={() => {
                setIsModalLogin(false);
                errorCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={registerModal}
        onClose={() => setRegisterModal(false)}
        onOutsideClick={() => setRegisterModal(false)}
      >
        {/* <Register onClose={()=> setRegisterModal(false)}/> */}
        <div className={styles.registerDiv}>
          <h1>Create an new account</h1>
          <label className={styles.inputlabel}>First Name</label>
          <TextInput1
            className={styles.registerInput}
            // label="First Name"
            name="name"
            autoFocus={true}
            type="text"
            placeholder="Enter first name"
            onChange={(e) => {
              firstNameFunction(e);
            }}
          />
          {firstNameError && (
            <div className={styles.alert}>{firstNameError}</div>
          )}
           <label className={styles.inputlabel}>Last Name</label>
          <TextInput1
            className={styles.registerInput}
         
            name="name"
            type="text"
            placeholder="Enter last name"
            onChange={(e) => {
              lastNameFunction(e);
            }}
          />
          {lastNameError && <div className={styles.alert}>{lastNameError}</div>}
          <label className={styles.inputlabel}>Email</label>
          <TextInput1
            className={styles.registerInput}
           
            name="email"
            type="email"
            placeholder="Enter email"
            onChange={(e) => {
              emailFunctions(e);
            }}
          />
          {emailError && <div className={styles.alert}>{emailError}</div>}
          <label className={styles.inputlabel}>Mobile Number</label>
          <TextInput1
            className={styles.registerInput}
            required
           
            name="number"
            //type="number"
            placeholder="+91 9897327823"
            onChange={(e) => {
              mobileFunction(e);
            }}
          />
          {mobError && <div className={styles.alert}>{mobError}</div>}
          <label className={styles.inputlabel}>Password</label>
          <TextInput1
            className={styles.registerInput}
           
            name="password"
            type={passwordShown ? "text" : "password"}
            placeholder="Enter password"
            onChange={(e) => {
              passwordFunctions(e);
            }}
          />
          <div className={styles.passwordShow}>
            {passwordShown ? (
              <BsFillEyeFill
                onClick={() => {
                  setPasswordshown(false);
                }}
              />
            ) : (
              <BsFillEyeSlashFill
                onClick={() => {
                  setPasswordshown(true);
                }}
              />
            )}
          </div>

          {passwordError && <div className={styles.alert}>{passwordError}</div>}
          <label className={styles.inputlabel}>Confirm Password</label>
          <TextInput1
            className={styles.registerInput}
          
            password
            name="password"
            type={passwordShown1 ? "text" : "password"}
            placeholder="Enter confirm password"
            onKeyPress={(e) => e.key === "Enter" && createAccount()}
            onChange={(e) => {
              confirmPasswordFunction(e);
            }}
          />
          <div className={styles.passwordShow1}>
            {passwordShown1 ? (
              <BsFillEyeFill
                onClick={() => {
                  setPasswordshown1(false);
                }}
              />
            ) : (
              <BsFillEyeSlashFill
                onClick={() => {
                  setPasswordshown1(true);
                }}
              />
            )}
          </div>
          {confirmPasswordError && (
            <div className={styles.alert}>{confirmPasswordError}</div>
          )}
          {errors && <div className={styles.alert}>{errors}</div>}
          <div className={styles.registerTxt}>
          <span  className={styles.impo}>IMPORTANT : </span><span className={styles.astrisk}>*</span> All are required fields.
          </div>
          <div className={styles.registerTxt}>
     
            Already have an account? Please login{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                setRegisterModal(false);
                setIsModalLogin(true);
              }}
            >
              here
            </span>
          </div>
          <div className={styles.registerBtn}>
            <button
              className={cn("button", styles.registerbutton)}
              onClick={() => createAccount()}
            >
              Register
              {loadings && <Spin size="small" />}
            </button>
            <button
              className={cn("button", styles.registerbutton)}
              onClick={() => {
                setRegisterModal(false);
                errorCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </OutsideClickHandler>
  );
};
export default User;

import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Users from "./Users";
import Control from "./Control";
import Options from "./Options";
import Modal from "../../components/Modal";
import Purchase from "../../components/Purchase";
import { useSnackbar } from "notistack";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { AddCommnets, GetAssetById, GetUSD } from "../../services/ApiServices";
import Loader from "../../components/Loader";
import TextInput from "../../components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import Bid from "../../mocks/bids";

const navLinks = ["Info", "Owners", "History", "Chain Info"];

const categories = [
  {
    category: "black",
    content: "art",
  },
  {
    category: "purple",
    content: "unlockable",
  },
];

const users = [
  {
    name: "Raquel Will",
    position: "Owner",
    avatar: "/images/content/avatar-2.jpg",
    reward: "/images/content/reward-1.svg",
  },
  {
    name: "Selina Mayert",
    position: "Creator",
    avatar: "/images/content/avatar-1.jpg",
  },
];

const Item = (props) => {
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [asset, setAsset] = useState({});
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [nav, setNav] = useState("");
  const [item, setItem] = useState({});
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");
  const [uid, setUid] = useState("");
  const [limit, setLimit] = useState(2);
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  const getAsset = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetAssetById(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAsset(result.posts[0]);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const handleSubmit = async (e, id) => {
    e.preventDefault();
    if (comment) {
      if (user.id) {
        let result;
        let body = {
          userId: user.id,
          postId: id,
          comment: comment,
        };
        try {
          result = await AddCommnets(body);
        } catch (e) {
          console.log(e);
        }

        try {
          if (result.success) {
            getAsset();
            setComment("");
            console.log(result);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        enqueueSnackbar("Please Login!", { variant: "error" });
      }
    }
  };

  useEffect(() => {
    let id = localStorage.getItem("uid");
    setUid(id);
  }, [id]);
  useEffect(() => {
    setIsLoading(true);
    getAsset();
  }, []);
  const getUSD = async () => {
    let result;
    try {
      result = await GetUSD();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUSD();
  }, []);

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {asset.id && (
        <div className={cn("section", styles.section)}>
          <div className={cn("container", styles.container)}>
            <div className={styles.bg}>
              <div className={styles.preview} style={{   backgroundImage: 
            `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),url(${asset.attachments[0].url})`}}>
                <div className={styles.categories}>
                  {categories.map((x, index) => (
                    <div
                      className={cn(
                        { "status-black": x.category === "black" },
                        { "status-purple": x.category === "purple" },
                        styles.category
                      )}
                      key={index}
                    >
                      {x.content}
                    </div>
                  ))}
                </div>

                {asset.attachments[0].fileType == "image" ? (
                  <>
                    <img
                      className={styles.bg}
                      srcSet={`${asset.attachments[0].url} 2x`}
                      src={asset.attachments[0].url}
                      alt="Card"
                    />
                    {/* <img
                  className={styles.image}
                    srcSet={`${asset.attachments[0].url} 2x`}
                    src={asset.attachments[0].url}
                    alt="Card"
                  /> */}
                  </>
                ) : (
                  <video
                    autoPlay
                    muted
                    loop
                    src={asset.attachments[0].url}
                    type="video/mp4"
                  />
                )}
                <Options className={styles.options} items={asset.likes} />
              </div>

              <div className={styles.comments}>
                <h3 className={cn("h6", styles.title)}>Reviews</h3>

                <form
                  className={styles.search}
                  onSubmit={(e) => handleSubmit(e, asset.id)}
                >
                  <input
                    className={styles.input}
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    name="comment"
                    placeholder="Leave your comments here"
                    // required
                  />
                  <button className={styles.result}>
                    <i
                      className="fa fa-fr fa-paper-plane"
                      style={{ color: "grey" }}
                    />
                  </button>
                </form>
              </div>
              <h3 className={cn("h6", styles.title)}>Comments</h3>

              <div>
                {asset.comments.slice(0, limit).map((data) => {
                  return (
                    <div className={styles.allComments}>
                      <div className={styles.commentItem}>
                        <img
                          src={data.userId.imageUrl}
                          className={styles.user}
                        />
                        <p className={styles.text}>{data.userId.userName}</p>
                      </div>
                      <i
                        className="fa fa-fr fa-comments"
                        style={{ color: "grey" }}
                      />
                      <span className={styles.text}>{data.comment}</span>
                      <hr />
                    </div>
                  );
                })}
              </div>
              {limit === 2 && (
                <p
                  className={styles.load}
                  onClick={() => {
                    setLimit(asset.comments.length);
                  }}
                >
                  Load More....
                </p>
              )}
            </div>
            <div className={styles.details}>
              <h3 className={cn("h3", styles.title)}>{asset.title}</h3>
              <div className={styles.tags}>
                {asset.tags.map((data) => {
                  return <span className={styles.tag}>{data}</span>;
                })}
              </div>
              <div className={cn("p", styles.edition)}>
                Edition {asset.stockAvailable} of {asset.totalSupply}
              </div>
              <div className={styles.cost}>
                <div className={cn("status-stroke-green", styles.price)}>
                  {asset.tokenPrice} {Bid}
                </div>
                <div className={cn("status-stroke-black", styles.price)}>
                ${Bid=="OKTO"? asset.tokenPrice: (value* asset.tokenPrice).toFixed(2)}
                </div>
                <div className={styles.counter}>
                  {asset.stockAvailable} in stock
                </div>
              </div>
              <div className={styles.info}>
                {asset.description} <br />
                {/* <a
                  href="https://ui8.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {asset.userId.email}
                </a> */}
              </div>

              <div className={styles.btns}>
                <button
                  className={cn("button", styles.button)}
                  onClick={() => {
                    setItem(asset);
                    setVisibleModalBid(true);
                  }}
                >
                  Buy Now
                </button>
              </div>
              <div className={styles.nav}>
                {navLinks.map((x, index) => (
                  <button
                    className={cn(
                      { [styles.active]: index === activeIndex },
                      styles.link
                    )}
                    onClick={() => {
                      setActiveIndex(index);
                      setNav(x);
                    }}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>

              <Users className={styles.users} items={asset} nav={nav} />
              {/* <Control className={styles.control} /> */}
            </div>
          </div>

          <Modal
            visible={visibleModalBid}
            onClose={() => setVisibleModalBid(false)}
          >
            <Purchase item={item} onClose={() => setVisibleModalBid(false)} />
          </Modal>
        </div>
      )}
    </>
  );
};

export default Item;

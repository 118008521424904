import React from "react"
import Landing from "../../images/default.jpg"
import styles from "./Home.module.sass"

const NewLanding = () => {

    return(
        <div>
            <img src={Landing} alt="landing" className={styles.landingImg} />
        </div>
    )
}

export default NewLanding;
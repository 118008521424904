import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./item.module.sass";
import Modal from "../../../components/Modal";
import Purchase from "../../../components/Purchase";
import { useParams } from "react-router-dom";
import {
  GetMaticPrice,
  GetOwnedAssetsByAlchemy,
} from "../../../services/ApiServices";
import Loader from "../../../components/Loader";
import { Col, Row } from "antd";
import TextInput1 from "../../../components/TextInput1";
import { HiPlus, HiMinus } from "react-icons/hi";
import { useWeb3React } from "@web3-react/core";

const ProfileDetail = (props, x) => {
  // const darkMode = useDarkMode(false);
  const { account, active } = useWeb3React();
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [assets, setAssets] = useState({});
  const [asset, setAsset] = useState({});
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [value, setValue] = useState([]);
  const [inventoryItem, setInventoryItem] = useState({});
  const [attributes, setAttributes] = useState(true);
  const [detail, setDetail] = useState(true);

  const getUSD = async () => {
    let result;
    try {
      result = await GetMaticPrice();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUSD();
  }, []);

  const getOwnedAssets = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetOwnedAssetsByAlchemy(account);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      return console.log("Error while fetch my assets api...", error);
    }

    let asset = result.ownedNfts.find((element) => {
      // if(parseInt(element.id.tokenId).toString() === id){
      //   setAssets(asset);
      // }
      return parseInt(element.id.tokenId).toString() === id;
    });
    setAssets(asset);
  };
  // console.log(assets,'assets of the page')
  useEffect(() => {
    if (account) {
      getOwnedAssets();
    }
  }, [id, account]);
  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      <div className={cn("section", styles.section)} ref={ref}>
        <div className={cn("container", styles.container)}>
          {active ? (
            <>
              {assets?.id?.tokenId && (
                <div>
                  <div className={styles.container1}>
                    <div className={styles.bg}>
                      <div className={styles.preview}>
                        <>
                          {assets?.metadata?.animation_url ? (
                            <>
                              <video
                                autoPlay
                                muted
                                loop
                                src={assets?.metadata?.animation_url}
                                type="video/mp4"
                              />
                            </>
                          ) : (
                            <img
                              alt=""
                              src={assets?.metadata?.image}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://wowtalkiesdevbucket.s3.ap-south-1.amazonaws.com/collections/no-image.png";
                              }}
                            />
                          )}
                        </>
                      </div>
                    </div>
                    <div className={styles.details}>
                      <div className={styles.title1}>
                        <h3 className={cn("h3", styles.title)}>
                          {assets?.metadata?.name}
                        </h3>
                      </div>
                      {assets.available && assets.supply && (
                        <div className={styles.priceRow}>
                          <Row>
                            <Col md={6} sm={7} xs={24}>
                              <div className={styles.priceHead}>Edition</div>
                            </Col>
                            <Col md={8} sm={14} xs={24}>
                              <p className={styles.test}>
                                {assets.available} of {assets.supply}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      )}
                      <div className={styles.priceRow}>
                        <Row>
                          <Col md={6} sm={7} xs={24}>
                            <div className={styles.priceHead}>Description</div>
                          </Col>
                          <Col md={18} sm={10} xs={24}>
                            <div className={styles.price}>
                              {assets.description
                                ? assets.description
                                : assets.collectionId?.description}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {assets?.tokenId || assets?.metadata?.tokenId ? (
                        <div className={styles.priceRow}>
                          <Row>
                            <Col md={6} sm={7} xs={24}>
                              <div className={styles.priceHead}>Token Id</div>
                            </Col>
                            <Col md={18} sm={10} xs={24}>
                              <div className={styles.price}>
                                {assets.tokenId || assets?.metadata?.tokenId}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                      <div className={styles.info}></div>
                      <div>
                        {/* <div className="detailcontainer">
                    <h2>DETALS:</h2>
                    <p>walletAddress:</p>
                    <p>Etherum:</p>
                  </div> */}
                      </div>
                    </div>
                  </div>
                  <div className={styles.newcontainers}>
                    <div className={styles.detail1}>
                      <div className={styles.headingFlex}>
                        <p className={styles.text1}>Attributes</p>
                        {attributes ? (
                          <HiMinus
                            onClick={() => {
                              setAttributes(false);
                            }}
                          />
                        ) : (
                          <HiPlus
                            onClick={() => {
                              setAttributes(true);
                            }}
                          />
                        )}
                      </div>
                      {attributes && (
                        <p className={styles.of}>
                          <>
                            {assets.metadata.attributes?.length > 0
                              ? assets.metadata.attributes.map((x, index) => (
                                  <Row>
                                    <Col md={6} sm={7} xs={24}>
                                      <p>{x.trait_type}</p>
                                    </Col>
                                    <Col md={18} sm={10} xs={24}>
                                      <p>{x.value}</p>
                                    </Col>
                                  </Row>
                                ))
                              : "No attributes found"}
                          </>
                        </p>
                      )}
                    </div>
                    <div className={styles.detail}>
                      <div className={styles.headingFlex}>
                        <p className={styles.text1}>Details</p>
                        {detail ? (
                          <HiMinus
                            onClick={() => {
                              setDetail(false);
                            }}
                          />
                        ) : (
                          <HiPlus
                            onClick={() => {
                              setDetail(true);
                            }}
                          />
                        )}
                      </div>
                      {detail && (
                        <div className={styles.of}>
                          <Row>
                            <Col md={6} sm={7} xs={24}>
                              <div className={styles.subhead}>
                                Contract Type
                              </div>
                            </Col>
                            <Col md={18} sm={10} xs={24}>
                              <p className={styles.textt}>
                                {assets?.contractMetadata.tokenType}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} sm={7} xs={24}>
                              <div className={styles.subhead}>
                                Contract Address
                              </div>
                            </Col>
                            <Col md={18} sm={10} xs={24}>
                              <p className={styles.textt}>
                                <a
                                  style={{ color: "#4a4a4a" }}
                                  target="_blank"
                                  rel="noreferrer"
                                  href={
                                    "https://mumbai.polygonscan.com/address/" +
                                    assets?.contract.address
                                  }
                                >
                                  {assets?.contract.address}
                                </a>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} sm={7} xs={24}>
                              <div className={styles.subhead}>Blockchain</div>
                            </Col>
                            <Col md={18} sm={10} xs={24}>
                              <p className={styles.description}>
                                {/* {assets?.chainName ? assets?.chainName : assets?.chainInfo?.chainName} */}
                                Polygon
                              </p>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <p className={styles.wallet}>Please connect your wallet</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileDetail;

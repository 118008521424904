import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import styles from "./User.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import profile from "../../../components/image.png";
import Report from "../../../components/Report";
import Modal from "../../../components/Modal";
import { Tabs } from "antd";
import Card2 from "../../../components/Card2";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  digiLocker,
  GetMainProfile,
  Panverification,
  Loginsignzy,
  GetEAadhar,
  NameMatch,
} from "../../../services/ApiServices";
import { useSnackbar } from "notistack";
import TextInput1 from "../../../components/TextInput1";
import { EditOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { useWeb3React } from "@web3-react/core";
import { minifyAddress } from "../../../utils/helpers";
import ProfileEdit from "../../ProfileEdit";
import Login from "../../Authentication/Login";
import Select from "react-select";
import firebase from "firebase/compat";
import {
  getDatabase,
  ref,
  child,
  push,
  update,
  onValue,
  remove,
} from "firebase/database";
import {
  getStorage,
  ref as referance,
  uploadBytes,
  getMetadata,
} from "firebase/storage";
import { Spin } from "antd";
import { getAuth } from "firebase/auth";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { identity } from "lodash";
import Loader from "../../../components/Loader";

const shareUrlFacebook = "https://ui8.net";
const shareUrlTwitter = "https://ui8.net";

const User = ({ className, item, getUser, isFollow }) => {
  const state = useSelector((state) => state);
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [visibleShare, setVisibleShare] = useState(false);
  const [visibleModalReport, setVisibleModalReport] = useState(false);
  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState("");
  const [aadhar, setAadhar] = useState({});
  const [username, setUsername] = useState("");
  const [nftAddress, setNftAddress] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [assets, setAssets] = useState([]);
  const [citizenModal, setCitizenModal] = useState(false);
  const [aadhaarName, setAadhaarName] = useState("");
  const [citizen, setCitizen] = useState("");
  const [kycVerification, setKycVerification] = useState("");
  const [authen, setAuthen] = useState({});
  const [main, setMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [panModal, setPanModal] = useState(false);
  const [basicAadharModal, setBasicAadharModal] = useState(false);
  const [basicPanModal, setBasicPanModal] = useState(false);
  const [panSignzy, setPanSignzy] = useState(false);
  const [preview, setPreview] = useState("");
  const [aadharFrontImg, setAadharFrontImg] = useState("");
  const [aadharBackImg, setAadharBackImg] = useState("");
  const [panImage, setPanImage] = useState("");
  const [nameerror, setNameerror] = useState("")
  const [file, setFile] = useState();
  const [userLastName, setUserLastName] = useState();
  const [url, setUrl] = useState("");
  const [emailId, setEmailId] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const storage = getStorage();
  const auth = getAuth();
  const [login, setLogin] = useState(localStorage.getItem("registerDate"));
  const [verified, setVerfied] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imgLoading, setImageLoading] = useState(false);
  const [aadharNoError, setAadharNoError] = useState("");
  const [aadharNo, setAadharNo] = useState("");
  const [aadharFrontUrl, setAadharFrontUrl] = useState("");
  const [aadharBackUrl, setAadharBackUrl] = useState("");
  const [testing, setTesting] = useState({});
  const [aadharFrntLoading, setAadharFrntLoading] = useState(false);
  const [aadharBackLoading, setAadharBackLoading] = useState(false);
  const [panLoading, setPanLoading] = useState(false);
  const [kycLoading, setKycLoading] = useState(true);
  const [panUploadImage, setPanUploadImage] = useState("");
  const [panResult, setPanResult] = useState({});
  const [panError, setPanError] = useState("");
  const [aadharError, setAadharError] = useState("");
  const [newPanerror, setNewPanerror] = useState("");
  const [aadharLoading, setAadharLoading] = useState(false);
  const [panResultLoading, setPanResultLoading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [pan, setPan] = useState("");
  const [adharerror, setAdharerror] = useState("");
  const [pannumber, setPannumber] = useState("");
  const [panName, setPanName] = useState("");
  const [aadharname, setAadharname] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [sample, setsample] = useState(false);
  const [open, setOpen] = useState(false);
  const db = getDatabase();
  var crypto = require("crypto-js");
  const [test, setTest] = useState(false);
  const Disabledbutton = () => {
    setDisabled(true);
  };
  // Decrypting the data using the same password key

  // var decrypted = crypto.AES.decrypt(encrypted, key).toString(crypto.enc.Utf8)
  // console.log(decrypted)

  // var decryptedData = crypto.AES.decrypt('U2FsdGVkX19VkIwOg14VQ4ayJlkPtkoE6Z/FZlaEpFQ=', '@wowTalkies123').toString(crypto.enc.Utf8);
  // console.log(decryptedData);

  // var encryptPanNum = crypto.AES.encrypt('Anandha Valli R', '@wowTalkies123').toString();
  // console.log(encryptPanNum)
  const panverify = (e) => {
    setPan(e.target.value);
  };
  const pannoverify = (e) => {
    setPannumber(e.target.value);
  };

  // useEffect(() => {
  //   let email = localStorage.getItem('email')
  //   setEmail(email)
  //   let name = localStorage.getItem('name')
  //   setUsername(name)
  //   localStorage.setItem("lastName", userLastName)
  //   localStorage.setItem("registerDate", login)
  //   let uid = localStorage.getItem('uid')
  //   setUid(uid)
  //   let address = localStorage.getItem('nftAddress')
  //   setNftAddress(address)
  // }, [citizen, url, uid]);
  useEffect(() => {
    const user = auth.currentUser;
    // if (user !== null) {
    //   // setEmailId(user?.email);
    //   setUid(user?.uid);
    let emails = localStorage.getItem("email");
    setEmailId(emails);
    return onValue(
      ref(db, process.env.REACT_APP_FIREBASE_URL + user?.uid),
      (snapshot) => {
        const username =
          (snapshot.val() && snapshot.val().firstName) || "username";
        setUsername(username);
        const lastname =
          (snapshot.val() && snapshot.val().lastName) || "username";
        setUserLastName(lastname);
        const mobileNumber =
          (snapshot.val() && snapshot.val().phoneNumber) || "username";
        setMobileNumber(mobileNumber);
        const email = snapshot.val() && snapshot.val().email;
        setEmail(email);
        // console.log(snapshot.val())
      },
      {
        onlyOnce: true,
      }
    );
    // }
    // else return
  }, [username, emailId, userLastName, auth?.currentUser, mobileNumber]);
  // useEffect(() => {
  //   const user = auth.currentUser;
  //   // if (user !== null) {
  //   //   // setEmailId(user?.email);
  //   //   setUid(user?.uid);
  //   let emails = localStorage.getItem("email");
  //   setEmailId(emails);
  //   return onValue(
  //     ref(db, process.env.REACT_APP_FIREBASE_URL + user?.uid),
  //     (snapshot) => {
  //       const  aadhaarName =
  //         (snapshot.val() && snapshot.val().
  //         aadhaarName) || "username";
  //       setAadharname(aadharname);
  //     },
  //     {
  //       onlyOnce: true,
  //     }
  //   );
  //   // }
  //   // else return
  // }, [aadhaarName]);

  // useEffect(() => {
  //   getMainpaginate(account)
  // }, [account])
  let id = account;

  const verifyPan = async () => {
    setPanResultLoading(true);
    if (!pan) {
      setNewPanerror("Please enter the PAN Name");
      setPanResultLoading(false);
    } else if (!pannumber) {
      setNewPanerror("Please enter the PAN Number");
      setPanResultLoading(false);
    } else {
      let result;
      let body = {
        type: "individualPan",
        urls: [],
        essentials: {
          name: pan,
          number: pannumber,
        },
      };
      try {
        result = await Panverification(body);
        setPanError("");
        if (result) {
          setIsOpen(false);
          setPanResult(result);
          if (result.error === "Invalid Pan Number" ) {
            setNewPanerror("Please enter a valid Pan Number");
            setPanResultLoading(false);
          
          }
           else if (result.error ===  "PAN not found" ){
            setNewPanerror("Invalid PAN detail. Please enter correct PAN detail.");
            setPanResultLoading(false);
           }
           else {
            setNewPanerror("");
            setPanResultLoading(true);
            if (result.verificationResult.response.result.verified === true) {
              setNewPanerror("");
              setPanResultLoading(false);
              setPanSignzy(false);
              Namematch(result.verificationResult.response.result.upstreamName);
              setOpen(true);
            }
            else {
              if (result.verificationResult.response.result.verified === false ) {
                console.log("5ghasd")
                setNewPanerror("Invalid PAN detail. Please enter correct PAN detail.");
                setPanResultLoading(false);
              }
            }

            setPanName(result.verificationResult.response.result.upstreamName);
           

            var encryptPanNum = crypto.AES.encrypt(
              result.verificationResult.response.number,
              "@wowTalkies123"
            ).toString();
            var encryptPanName = crypto.AES.encrypt(
              result.verificationResult.response.result.upstreamName,
              "@wowTalkies123"
            ).toString();
            update(
              ref(db, process.env.REACT_APP_FIREBASE_URL + uid + "/PAN/"),
              {
                panNumber: encryptPanNum,
                panName: encryptPanName,
                panVerifiedStatus: result.verificationResult.response.result.verified,
                panVerifiedDate: moment().format("DD-MM-YYYY , LT"),
                panMessage: result.verificationResult.response.result.message,
              }
            )
              .then((data) => {
                console.log(data, "account");
                setPanResultLoading(false);
                // setBasicPanModal(false)
                // setPanModal(true);
              })
              .catch((error) => {
                console.log(error);
                // setPanResultLoading(false);
              });
          }
        }
      } catch (e) {
        setPanResultLoading(false);
        // failedKyc()
        return console.log(e);
      }
    }
  };

  const digilocker = async () => {
    setLoading(true);
    let result;
    let body = {
      task: "url",
    };
    try {
      result = await digiLocker(body);
      if (result) {
        setUrl(result.digilockerUrl.result);
        setIsOpen(true);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loginsignzy = async () => {
    setLoading(true);
    let result;
    let body = {
      username: process.env.REACT_APP_SIGNZY_USERNAME,
      password: process.env.REACT_APP_SIGNZY_PASSWORD ,
    };
    try {
      result = await Loginsignzy(body);
      if (result) {
        setTesting(result);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getAdhar = async () => {
    setLoading(true);
    let result;
    let userId = testing.userId;
    let accessToken = testing.id;
    let body = {
      task: "getEadhaar",
      essentials: {
        requestId: url.requestId,
      },
    };
    try {
      result = await GetEAadhar(userId, accessToken, body);

      if (result) {
        if (result.error) {
          setIsOpen(true);
          setAdharerror(
            "Please complete the Aadhar verification in Signzy DigiLocker"
          );
        }
        else {
        if (!result?.error) {
       
          setIsOpen(false);
          setPanSignzy(true);
          console.log(result);
          setAadhaarName(result.result.name);
          var encryptAadharname = crypto.AES.encrypt(
            result.result.name,
            "@wowTalkies123"
          ).toString();

          update(
            ref(db, process.env.REACT_APP_FIREBASE_URL + uid + "/Aadhaar/"),
            {
              aadhaarNumber: result.result.uid,
              aadhaarName: encryptAadharname,
              aadhaarVerifiedStatus: result.statusCode === 400 ? false : true,
              aadhaarVerifiedDate: moment().format("DD-MM-YYYY , LT"),
            }
          );
          setLoading(false);
        }
      }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const Namematch = async (name) => {
    setLoading(true);
    let result;
    let userId = testing.userId;
    let accessToken = testing.id;
    let body = {
      task: "nameMatchV2",
      essentials: {
        nameBlock: {
          name1: aadhaarName,
          name2: name,
        },
      },
    };
    try {
      result = await NameMatch(userId, accessToken, body);

      if (result) {
        console.log(result.result.name1_vs_name2_matchReason);
        if (result.result.name1_vs_name2_matchScore > 0.75) {
          setOpen(true);
          setPanSignzy(false);
          setLoading(false);
          setNameerror("")
        } 
          if (result.result.name1_vs_name2_matchResult === "No match") {
          setNameerror(result.result.name1_vs_name2_matchResult)
          setPanSignzy(false);
          setLoading(false);
          setOpen(true)
        
        }
        update(
          ref(db, process.env.REACT_APP_FIREBASE_URL + uid + "/NameMatch/"),
          {
            MatchReason: result.result.name1_vs_name2_matchReason,
            Matchresult: result.result.name1_vs_name2_matchResult,
            MatchScore: result.result.name1_vs_name2_matchScore,
            MatchStatus:    result.result.name1_vs_name2_matchScore > 0.75 ? true : false,
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  function myFunction() {
    return enqueueSnackbar("Address copied to clipboard", {
      variant: "success",
    });
  }

  function copyToClipboard() {
    if (navigator.clipboard && window.isSecureContext) {
      console.log("copied");
      myFunction();
      return navigator.clipboard.writeText(account);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = account;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      console.log("error");
      myFunction();
      return new Promise((res, rej) => {
        console.log("error in console");
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  const handle = async (e) => {
    let file = e.target.files[0];
    setFile(file);

    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setPreview("");
    }
  };

  const upload = async () => {
    setIsLoading(true);
    let bucketName = "webProfileImg/";
    const storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
    let uploadTask = storageRef.put(file);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      () => {},
      async () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          console.log("logo url", url);
          const db = getDatabase();
          update(ref(db, process.env.REACT_APP_FIREBASE_URL + uid), {
            imageurl: url,
          })
            .then(() => {
              console.log(user, "account");
              getImageData();
            })
            .catch((error) => {
              console.log(error);
            });
          setIsLoading(false);
          setIsModalVisible(false);
        });
      }
    );
  };

  const getImageData = () => {
    setImageLoading(true);
    const user = auth.currentUser;
    if (user !== null) {
      setUid(user?.uid);
      return onValue(
        ref(db, process.env.REACT_APP_FIREBASE_URL + user?.uid),
        (snapshot) => {
          const image = snapshot.val() && snapshot.val().imageurl;
          // console.log(snapshot.val().imageurl, "snapshot")
          setImageUrl(image);
          setImageLoading(false);
        },
        {
          onlyOnce: true,
        }
      );
    } else return setImageLoading(false);
  };

  const getKycData = () => {
    setKycLoading(true);
    setsample(true);
    const uid = localStorage.getItem("uid");
    const user = auth?.currentUser?.uid || uid;

    console.log(user);
    if (user !== null) {
      setUid(user);
      return onValue(
        ref(db, process.env.REACT_APP_FIREBASE_URL + user),
        (snapshot) => {
          const kyc =
            (snapshot.val() && snapshot.val().kycStatus) ||
            "Pending Verification";
          setVerfied(kyc);
          setKycLoading(false);
          setsample(false);
        },
        {
          onlyOnce: true,
        }
      );
    } else return setKycLoading(false);
  };

  const handleimage = async (e) => {
    setAadharFrntLoading(true);
    setAadharError("");
    let file = e.target.files[0];
    if (!new RegExp("image/*").test(e.target.files[0].type)) {
      setAadharFrntLoading(false);
      setAadharError("Please upload only Image (png,jpeg,jpg,gif format only)");
    } else {
      setAadharError("");
      setAadharFrontImg(
        file.name.length > 16
          ? file.name.slice(0, 8) + "...." + file.name.slice(-8)
          : file.name
      );
      let bucketName = "aadharcard/";
      const storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        () => {},
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            console.log("logo url", url);
            setAadharFrontUrl(url);
            setAadharFrntLoading(false);
          });
        }
      );
    }
  };

  const handleimage1 = async (e) => {
    setAadharError("");
    setAadharBackLoading(true);
    let file = e.target.files[0];
    if (!new RegExp("image/*").test(e.target.files[0].type)) {
      setAadharBackLoading(false);
      setAadharError("Please upload only Image (png,jpeg,jpg,gif format only)");
    } else {
      setAadharError("");
      setAadharBackImg(
        file.name.length > 16
          ? file.name.slice(0, 8) + "...." + file.name.slice(-8)
          : file.name
      );
      let bucketName = "aadharcard/";
      const storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        () => {},
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            console.log("logo url", url);
            setAadharBackUrl(url);
            setAadharBackLoading(false);
          });
        }
      );
    }
  };

  const handlepanimage = async (e) => {
    setPanLoading(true);
    setPanError("");
    let file = e.target.files[0];
    if (!new RegExp("image/*").test(e.target.files[0].type)) {
      setPanLoading(false);
      setPanError("Please upload only Image (png,jpeg,jpg,gif format only)");
    } else {
      setPanError("");
      setPanImage(
        file.name.length > 16
          ? file.name.slice(0, 8) + "...." + file.name.slice(-8)
          : file.name
      );
      let bucketName = "pancard/";
      const storageRef = firebase.storage().ref(`${bucketName}/${file.name}`);
      let uploadTask = storageRef.put(file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        () => {},
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            console.log("logo url", url);
            setPanUploadImage(url);
            setPanLoading(false);
          });
        }
      );
    }
  };

  const foreignKyc = () => {
    update(ref(db, process.env.REACT_APP_FIREBASE_URL + uid), {
      kycStatus: "Foreigner",
    })
      .then(() => {
        getKycData();
        localStorage.setItem("kycStatus", "Foreigner");
        console.log(user, "account");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const failedKyc = () => {
    update(ref(db, process.env.REACT_APP_FIREBASE_URL + uid), {
      kycStatus: "Failed",
    })
      .then(() => {
        getKycData();
        console.log(user, "account");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifiedKyc = () => {
    update(ref(db, process.env.REACT_APP_FIREBASE_URL + uid), {
      kycStatus: "Verified",
    })
      .then(() => {
        getKycData();
        localStorage.setItem("kycStatus", "Verified");
        console.log(user, "account");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const errorCancel = () => {
    console.log("error cancelled");
    setAadharError("");
    setAadharFrontImg("");
    setAadharFrontUrl("");
    setAadharBackImg("");
    setAadharBackUrl("");
    setPanError("");
    setPanImage("");
    setPanUploadImage("");
  };
  const clear = () => {
    setPreview("");
  };

  useEffect(() => {
    getImageData();
    getKycData();
  }, [auth?.currentUser, imageUrl, authen, aadhar, verified]);

  useEffect(() => {
    let uid = localStorage.getItem("uid");
    localStorage.setItem("mobileNo", mobileNumber);
    setUid(uid);

    // if (url) {
    //   setIsOpen(true);
    // }
  }, [uid, verified, url]);

  return (
    <>
      <div className={cn(styles.user, className)}>
        <div className={styles.avatar}>
          {imgLoading ? (
            <Spin style={{ margin: "0 auto" }} />
          ) : (
            <img src={imageUrl ? imageUrl : profile} alt="Avatar" />
          )}
          <div className={styles.edit}>
            <FiEdit
              onClick={() => {
                setIsModalVisible(true);
              }}
            />
          </div>
        </div>
        {username && userLastName ? (
          <div className={styles.name}>
            {username === "username" ? email : username}{" "}
            {userLastName === "username" ? null : userLastName}
          </div>
        ) : (
          <Loader className={styles.nameLoad} />
        )}
        {active && (
          <div className={styles.code}>
            <div className={styles.number}>{minifyAddress(account, 6)}</div>
            <button
              className={styles.copy}
              onClick={() => {
                copyToClipboard();
              }}
            >
              <Icon name="copy" size="16" />
            </button>
          </div>
        )}
        <div className={styles.kyc}>
          <span>KYC Status:</span>
          {sample && !verified ? (
            <Spin />
          ) : (
            <>
              {verified === "Failed" ? (
                <p className={styles.verification2}>Failed</p>
              ) : (
                <p
                  className={
                    verified === "Pending Verification"
                      ? styles.verification
                      : styles.verification1
                  }
                  // onClick={() => {
                  // //  setOpen(true)
                  //   setPanSignzy(true);
                    
                  // }}
                  onClick={() => { if (verified === "Pending Verification") { setCitizenModal(true) } else setCitizenModal(false) }}
                >
                  {verified}{" "}
                </p>
              )}
            </>
          )}
        </div>
        <div className={styles.control}></div>
        <div className={styles.card}></div>
        <div className={styles.note}>
          Member since {moment(login).format("MMM DD, YYYY")}
        </div>
      </div>
      <Modal
        visible={isopen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className={styles.modalDiv}>
          {/* <iframe src={url.url}></iframe> */}
          <div className={styles.heading}>Step 1 - Aadhar Verification:</div>
          <h3>
            (This redirects to an external link - Signzy Digilocker - in a new
            tab.
            <br />
            Complete the required details for verification and come back here.)
          </h3>
          {disabled ? (
            <button
              className={cn("button", styles.button1)}
              styles={{ cursor: "pointer" }}
            >
              Verify
            </button>
          ) : (
            <a href={url.url} target="_blank">
              <button
                className={cn("button", styles.button)}
                styles={{ cursor: "pointer" }}
                onClick={() => Disabledbutton()}
              >
                Verify
              </button>
            </a>
          )}
          <h1 styles={{ marginTop: "26px" }}>
            Step 2 - PAN Card Verification:
          </h1>
          <button
            className={cn("button", styles.button)}
            styles={{ cursor: "pointer" }}
            onClick={() => {
              loginsignzy();
              getAdhar();
              setIsOpen(false);
            }}
          >
            Verify
          </button>
          {adharerror && <p className={styles.errorMsg}>{adharerror}</p>}
        </div>
      </Modal>

      <Modal
        visible={visibleModalReport}
        onClose={() => setVisibleModalReport(false)}
      >
        <Report />
      </Modal>

      <Modal
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          clear();
        }}
      >
        <div className={styles.page}>
          <div>
            <div className={cn("container", styles.container1)}>
              <div className={styles.top}>
                <h1 className={cn("h4", styles.title)}>Edit profile</h1>
              </div>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.user1}>
                    <div className={styles.avatar1}>
                      {preview ? (
                        <img src={preview} alt="Avatar" />
                      ) : (
                        <img src={imageUrl ? imageUrl : profile} alt="Avatar" />
                      )}
                    </div>
                    <div className={styles.details}>
                      <div className={styles.stage}>Profile photo</div>
                      <div className={styles.text}>
                        We recommend an image of at least 400x400.
                      </div>
                      <div className={styles.file}>
                        <button
                          className={cn(
                            "button-stroke button-small",
                            styles.button
                          )}
                          styles={{ cursor: "pointer" }}
                        >
                          Upload
                        </button>
                        <input
                          className={styles.load}
                          type="file"
                          onChange={handle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.list}>
                    <div className={styles.nameFlex}>
                      <div className={styles.firstNameDiv}>
                        <h5>FirstName:</h5>
                        <div className={styles.firstNameInput}>
                          {username === "username" ? "----" : username}
                        </div>
                      </div>
                      <div className={styles.lastNameDiv}>
                        <h5>LastName:</h5>
                        <div className={styles.lastNameInput}>
                          {userLastName === "username" ? "----" : userLastName}
                        </div>
                      </div>
                    </div>
                    <div className={styles.emailFlex}>
                      <div className={styles.emailDiv}>
                        <h5>Email:</h5>
                        <div className={styles.emailInput}>{emailId}</div>
                      </div>
                      <div className={styles.mobDiv}>
                        <h5>Mobile No:</h5>
                        <div className={styles.mobInput}>
                          {mobileNumber === "username" ? "----" : mobileNumber}
                        </div>
                      </div>
                    </div>
                    <div className={styles.btns}>
                      <button
                        className={cn("button", styles.button)}
                        onClick={() => {
                          upload();
                        }}
                      >
                        Update Profile {isLoading && <Spin />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal visible={citizenModal} onClose={() => setCitizenModal(false)}>
        <div className={styles.modalDiv}>
          <h1>Select your country of citizenship</h1>
          <div className={styles.citizenBtn}>
            <button
              className={cn("button-stroke", styles.kycButton)}
              onClick={() => {
                setCitizen("India");
                setCitizenModal(false);
                digilocker();
                loginsignzy();
              }}
            >
              India
            </button>
            <button
              className={cn("button-stroke", styles.kycButton)}
              onClick={() => {
                foreignKyc();
                setCitizenModal(false);
              }}
            >
              Other Country
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={panSignzy}
        onClose={() => {
          errorCancel();
          setPanSignzy(false);
        }}
      >
        <div className={styles.modalDiv}>
          <>
            <div className={styles.heading1}>
              Step 2 - PAN Card Verification:{" "}
            </div>
            <div className={styles.loginDiv}>
              <TextInput1
                className={styles.registerInput}
                // type="text"
                label="Name on PAN Card"
                name="text"
                onChange={(e) => {
                  panverify(e);
                }}
                required
              />
              <TextInput1
                // type="text"
                label="PAN NUMBER"
                name="number"
                onChange={(e) => {
                  pannoverify(e);
                }}
                required
              />
            </div>

            <button
              className={cn("button", styles.kycButton1)}
              onClick={() => {
                verifyPan();
                setIsOpen(false);
              }}
            >
              
              Submit {panResultLoading && <Spin />}{" "}
            </button>
            {newPanerror && <p className={styles.errorMsg}>{newPanerror}</p>}
          </>
        </div>
      </Modal>
      <Modal
        visible={open}
        onClose={() => {
          errorCancel();
          setOpen(false);
        }}
      >
        <div className={styles.popover}>
          <div>{nameerror ? "KYC not completed successfully." : "You have successfully completed the KYC process."}</div>
          <button
            className={cn("button", styles.okButton)}
            onClick={() => {
              console.log(nameerror, "afsnfdsng")
              if(nameerror === "No match"){
                console.log(nameerror, "afsnfdsng")
              setPanSignzy(false);
              setIsOpen(false);
              setOpen(false);
              }
              else {
               
              setPanSignzy(false);
              setIsOpen(false);
              setOpen(false);
              verifiedKyc()

              }
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};

export default User;

import React,{useEffect, useState} from "react";
import cn from "classnames"
import styles from "./buyMatic.module.sass"
import { useWeb3React } from "@web3-react/core";
import {
  getDatabase,
  ref,
  onValue,
} from "firebase/database";
import { getAuth } from "firebase/auth";

const BuyMatic = () => {
  const auth = getAuth();
  const db = getDatabase();
  const { account } = useWeb3React();
  const [emailId, setEmailId] = useState(localStorage.getItem("email"));

  const getData = (uid) => {
    const userId = auth?.currentUser?.uid;
    return onValue(
      ref(db, process.env.REACT_APP_FIREBASE_URL + userId),
      (snapshot) => {
        const username =
          (snapshot.val() && snapshot.val().email) || "username";
        setEmailId(username);
      },
      {
        onlyOnce: true,
      }
    );
  };

  useEffect(() => {
    getData()
  },[emailId,account])
    return(
        <div>
      <div className={cn("container",styles.container)}>
      <h3 className={cn("h4", styles.title)}>Buy MATIC</h3>
        <iframe sandbox="allow-same-origin allow-scripts"
        src={process.env.REACT_APP_ONMETA_URL+`?address=${account}&email=${emailId}`} 
        style={{width:"100%",height:"600px"}} scrolling="no"></iframe>
        </div>
        </div>
    )
}

export default BuyMatic;

import WalletConnectProvider from "@walletconnect/web3-provider";
import { Posts } from "../json/Assets"
import AppUrls from "../apiUrl";


//Magic wallet lib import
const { Magic } = require('magic-sdk');

const customNodeOptions = {
  rpcUrl: 'https://rpc-mainnet.maticvigil.com/', // Polygon RPC URL
  chainId: 137, // Polygon chain id
}
const APIURL = "http://18.221.3.102:3200";


// -------------------- Asset --------------------

export async function GetAssetbynew() {
  let response;
  try {
    response = await fetch(
      APIURL + "/v1/assetsbynewitems",
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Getalltags() {
  let response;
  try {
    response = await fetch(
      APIURL + "/emp/GetCategories",
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAccount() {
  let response;
  try {
    response = await fetch(
      APIURL + "/v1/assetByaddress/",
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetNewitem() {
  let response;
  try {
    response = await fetch(
      APIURL + "/emp/Getassetslatest",
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Getcollection() {
  let response;
  try {
    response = await fetch(
      APIURL + "/emp/GetAllCollections",
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBrowser() {
  let response;
  try {
    response = await fetch(
      APIURL + "/v1/GetCategories",
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSearchAsset() {
  let response;
  try {
    response = await fetch("/postDetails/https://84jajvnvva.execute-api.us-east-1.amazonaws.com/?limit=", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({}),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostAsset(body) {
  let response;
  try {
    response = await fetch("/postDetails/posts", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateAsset(body, id) {
  let response;
  try {
    response = await fetch("/postDetails/posts?postid=" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetById(id) {
  let response;
  try {
    response = await fetch(APIURL + "/emp/asset/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetUserAssets(uid) {
  let response;
  try {
    response = await fetch("/postDetails/userposts?userId=" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetpaginate(lastkey) {
  let response;
  try {
    response = await fetch(
      "/postDetails/https://84jajvnvva.execute-api.us-east-1.amazonaws.com/?limit=6",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ lastKey: lastkey }),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// =--------------- Tag --------------
export async function GetPostByTags(tag) {
  let response;
  try {
    response = await fetch("/postDetails/tags?tags=" + tag, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function Getitem(tag) {
  let response;
  try {
    response = await fetch("/postDetails/tags?tags=" + tag, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// --------------USD value for Eth------------

export async function GetUSD() {
  let response;
  try {
    response = await fetch("https://min-api.cryptocompare.com/price?fsym=ETH&tsyms=USD", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response) {
    return response.json();
  }
}

// -------------- User --------------
export async function GetUser(uid) {
  let response;
  try {
    response = await fetch("/userDetails/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBuyerUser(uid) {
  let response;
  try {
    response = await fetch("/buyerDetails/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateUser(body, uid) {
  let response;
  try {
    response = await fetch("/userDetails/users?user=" + uid, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUsers() {
  let response;
  try {
    response = await fetch(APIURL + "/v1/getAssetss", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function verifyAddress(address) {
  let response;
  try {
    response = await fetch("/userDetails/verify", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ nftAddress: address }),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function verifyBuyerAddress(address) {
  let response;
  try {
    response = await fetch("/buyerDetails/verifybuyer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ address: address }),
    });
    if (response && response.status === 200) {
      return await response.json();
    } else {
      let res = await response.json();
      throw new Error(res.message);
    }

  } catch (e) {
    throw new Error(e.message);
  }
}

export async function getInventoryDetails(id) {
  let response

  try {
    response = await fetch(APIURL + `/emp/GetassetById/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*"
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}
export async function Getasseytagsbyid(id) {
  let response

  try {
    response = await fetch(APIURL + `/v1/assets/tags/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*"
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}
export async function getitemDetails(id) {
  let response

  try {
    response = await fetch(APIURL + `/v1/item/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*"
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}
export async function createUser(body) {
  let response;
  try {
    response = await fetch("/userDetails/users", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

/********             Aaron Added    *****/
// start
export async function createTxData(body) {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/createTxData`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//   end

export async function createBuyerUser(body) {
  let response;
  try {
    response = await fetch("/buyerDetails/buyers", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error ?? message.message);
  }
}
export async function userUpdate(body, id) {
  let response;
  try {
    response = await fetch("/userDetails/users?user=" + id, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// ---------------------- Following/Followers ------------

export async function GetFollowers(body) {
  let response;

  try {
    response = await fetch("/followerDetails/followers", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ followers: body }),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getProvider() {
  let user, meta, provider;
  try {
    switch (localStorage.getItem("walletType")) {
      case "metamask":
        if (!window.ethereum)
          throw new Error("No crypto wallet found. Please install it.");
        meta = await window.ethereum.send("eth_requestAccounts");
        provider = window.ethereum;
        // console.log("provider metamask", window.ethereum)
        break;

      case "magic":
        //Initiate magic wallet with apikey
        const m = new Magic('pk_test_0774D6CA0410BE17', { testMode: true, network: customNodeOptions }); // ✨ //pk_live_FD8BF3DDCBDACFB5
        // log in using magic wallet
        if (m.user && m.user.isLoggedIn) {
          provider = m.rpcProvider;
        } else {
          meta = await m.auth.loginWithMagicLink({ email: 'test+success@magic.link' });
          provider = m.rpcProvider;
        }
        break;

      case "walletConnect":
        // log in using connect wallet
        const walletProvider = new WalletConnectProvider({
          rpcUrl: "https://polygon-rpc.com",
          chainId: 137,
          rpc: {
            137: "https://polygon-rpc.com"
          },
        });
        await walletProvider.enable();
        provider = walletProvider;
        break;

      default:
        throw new Error("Wallet could not found");
    }
  } catch (e) {
    throw new Error(e.message);
  }
  // console.log("return provider", window.ethereum)
  return provider;
}

export async function GetFollowings(followings) {
  let response;
  try {
    response = await fetch("/followerDetails/followings", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ followers: followings }),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function AddToFollower(UId, FId) {
  let response;
  try {
    response = await fetch("/followerDetails/" + UId + "?follow=" + FId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}
export async function UnFollow(UId, FId) {
  let response;
  try {
    response = await fetch(
      "/followerDetails/followers/" + UId + "?unfollow=" + FId,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}

// ------------ Comments ---------------
export async function AddCommnets(body) {
  let response;
  try {
    response = await fetch("/commentDetails/comments", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}

//--------------Add Like -----------------

export async function Like(body, postId) {
  let response;
  try {
    response = await fetch("/addEjades?like=" + postId, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message);
  }
}
// ------------Get USD ------------
export async function GetUSDPrice() {
  let response;
  try {
    response = await fetch(
      "https://metadata.cyberdogz.io/v1/usdprices",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) { }

  if (response) {
    return response.json();
  }
}
export async function GetTrendingCollection() {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/getTrending`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetTopNfts(page, limit) {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/getTopNft?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetTopNftsByTags(tags) {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/getTopNft?tags=${tags}&limit=8`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetNftId(id) {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/getTopNft/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetTrendingId(id) {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/getTrending/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetCollection(id) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/collection/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function Getcollections() {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/getAllcollections`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetByCollection(id, page) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/assets/collection/${id}?limit=12&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetNftByAssetId(id) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/asset/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCollectionsByTags(category) {
  let response;
  try {
    response = await fetch(`${AppUrls.wowT}/getAllcollections?category=${category}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetFilter(body) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/orders`,
      // `http://localhost:3200/wowT/orders`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Getfilter(query, id, page) {
  let response;
  try {
    response = await fetch(
      `http://localhost:3200/v1/assetsfilter?type=${query}&collectionId=${id}&limit=12&page=${page}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateUser(body) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/createUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetMainProfile(account) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/user/assets/${account}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAssetProfile(account) {

  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/assets/owned?address=${account}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        }
        // body: JSON.stringify({}),
      }
    );
  } catch (e) { }


  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetOwnedAssets(address, tokenAddress, tokenAddress1) {
  let response, url;
  url = `https://deep-index.moralis.io/api/v2/${address}/nft?chain=rinkeby&token_addresses=${tokenAddress}&token_addresses=${tokenAddress1}`
  // url = cursor !== "" ? `/v1/user/assets/${address}&cursor=${cursor}&token_addresses=0xD1110AC6De43571460C272f0Ba495e0ead853Ed3&token_addresses=0x6a8e49e8bd22e3ca535a959a6aeec094ebf382b7` : `/v1/user/assets/${address}&token_addresses=0xD1110AC6De43571460C272f0Ba495e0ead853Ed3&token_addresses=0x6a8e49e8bd22e3ca535a959a6aeec094ebf382b7`;
  try {
    response = await fetch(
      url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "DOFQvx7tqd8bVUaDchSNuFiXVcF28xMtfk3vrdfbFGdNcCNW7sisToevl2wDOI6U"
        },
      }
    );
  } catch (e) {
    console.log("Error from get owners function...", e);
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getUsersAsssets(address, cursor) {

  let response, url;
  // url = cursor !== "" ? `${AppUrls.wowT}/user/assets/${address}&cursor=${cursor}` : `/v1/user/assets/${address}`;
  try {
    response = await fetch(`${AppUrls.wowT}/user/assets/${address}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_MORALIS_API_KEY
      }
    })
  } catch (error) {

  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    // console.log("Error from get owned assets api...",message);
    throw new Error(message.error);
  }
}

export async function getMyOwnedAssets(address, page) {
  let response;

  try {
    response = await fetch(`${AppUrls.wowT}/assets/owned?ownerAddress=${address}&limit=6&page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
  } catch (error) {

  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function updateAsset(id, body) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/asset/updateOwner/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function getAssets() {
  let response;

  try {
    response = await fetch(`${AppUrls.wowT}/assets`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
  } catch (error) {

  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function filterAsset(id, body, page) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/assets/collection/filter/${id}?limit=12&page=${page}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function PanVerify(body) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/verify`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  console.log(response)
  } catch (e) { throw new Error(e.message) }
  if (response.status === 200) {
    return await response.json();
  } 
  else if (response.status === 400) {
    return await response.json()
  }
  else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetOwnedAssetsByAlchemy(address) {
  let response;

  try {
    response = await fetch(`https://polygon-mumbai.g.alchemy.com/v2/mS38cFHkafTvlWRvrQA2bPQXyaYIoNt7/getNFTs/?owner=${address}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
  } catch (error) {

  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetMaticPrice() {
  let response;
  try {
    response = await fetch(
      "https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) { }

  if (response) {
    return response.json();
  }
}
export async function digiLocker(body) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/digiUrl`,
      {
        method: "post",
        headers: {
          'Accept-Language': 'en-US,en;q=0.8',
          "Content-Type": "application/json",
          "Accept": '*/*',
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { throw new Error(e.message) }
  if (response.status === 200) {
    return await response.json();
  } 
  else if (response.status === 400) {
    return await response.json()
  }
  else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Panverification(body) {
  let response;
  try {
    response = await fetch(
      `${AppUrls.wowT}/panVerify`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  console.log(response)
  } catch (e) { throw new Error(e.message) }
  if (response.status === 200) {
    return await response.json();
  } 
  else if (response.status === 400) {
    return await response.json()
  }
  else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEAadhar(userId,accessToken,body) {
  let response;
  try {
    response = await fetch(
      `https://signzy.tech/api/v2/patrons/${userId}/digilockers`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
          "Authorization":accessToken,
        },
        body: JSON.stringify(body),
      }
    );
  console.log(response)
  } catch (e) { throw new Error(e.message) }
  if (response.status === 200) {
    return await response.json();
  } 
  else if (response.status === 400) {
    return await response.json()
  }
  else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Loginsignzy(body) {
  let response;
  try {
    response = await fetch(
      ' https://signzy.tech/api/v2/patrons/login',
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  console.log(response)
  } catch (e) { throw new Error(e.message) }
  if (response.status === 200) {
    return await response.json();
  } 
  else if (response.status === 400) {
    return await response.json()
  }
  else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function NameMatch(userId,accessToken,body) {
  let response;
  try {
    response = await fetch(
      `https://signzy.tech/api/v2/patrons/${userId}/matchers`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
          "Authorization":accessToken,
        },
        body: JSON.stringify(body),
      }
    );
  console.log(response)
  } catch (e) { throw new Error(e.message) }
  if (response.status === 200) {
    return await response.json();
  } 
  else if (response.status === 400) {
    return await response.json()
  }
  else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
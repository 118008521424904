import React, { useState, useEffect } from "react";
import cn from "classnames"
import styles from "./collections.module.sass"
import { GetAssetByCollection, GetCollection,GetUSD,GetAssetFilter, Getfilter, GetMaticPrice, filterAsset } from "../../services/ApiServices";
import { useParams } from "react-router-dom"
import Eth from "../../images/eth 2.png";
import { Row, Col, Skeleton, Pagination } from "antd";
import { MdVerified } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import Loader from "../../components/Loader";
import {Link} from "react-router-dom"
import Pagenation from "../../pagenation";
import Filter from "../../Filter";
import Polygon from "../../images/polygon.png"
import noImg from "../../images/no img.png"
import Modal from "../../components/Modal";
import Purchase from "../../components/Purchase";
import Filter1 from "../../Filter1";
 
const AllCollections = () => {
    const [loading, setLoading] = useState(false)
    const [assetsLoading, setAssetsLoading] = useState(false);
    const [assets, setAssets] = useState([])
    const [usd, setUsd] = useState("")
    const [collection, setCollection] = useState({})
    const [totalDocs, setTotalDocs] = useState(0);
    const [page, setPage] = useState(1);
    const [item, setItem] = useState({})
    const [visibleModalBid, setVisibleModalBid] = useState(false)
    const { id } = useParams()

    const getCollection = async () => {
      setLoading(true);
      let result;
      try {
        result = await GetCollection(id);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
      try {
        if (result.success) {
          setLoading(false);
          setCollection(result.collection);
        }
      } catch (e) {
        setLoading(false);
  
        console.log(e);
      }
    };
    useEffect(() => {
      if (id) getCollection();
    }, [id]);
    const getAssetByCollection = async () => {
      setAssetsLoading(true);
      let result;
      try {
        result = await GetAssetByCollection(id, page);
      } catch (e) {
        console.log(e);
        setAssetsLoading(false);
      }
      try {
        if (result.success) {
          setAssetsLoading(false);
          setAssets(result.assets.docs);
          setTotalDocs(result.assets.totalDocs);
        }
      } catch (e) {
        setAssetsLoading(false);
  
        console.log(e);
      }
    };
    useEffect(() => {
      if (id) getAssetByCollection();
    }, [id, page]);
    const getUSD = async () => {
      let result;
      try {
        result = await GetMaticPrice();
      } catch (e) {
        console.log(e);
      }
  
      try {
        if (result) {
          setUsd(result.USD);
        }
      } catch (e) {
        console.log(e);
      }
    };
      
    useEffect(() => {
        // console.log(assets,"hi",collection)
        // getAssetByCollection()
        getUSD()
    }, [])

    const getfilter = async (query,id) => {
      setAssetsLoading(true); 
      let result;
      try {
        result = await Getfilter(query,id,page);
        if (result.success) {
          setAssetsLoading(false);
          setAssets(result.asset.docs);
          setPage(result.assets.page)
          setTotalDocs(result.assets.totalDocs);
        }
      }
      catch (e) {
        setAssetsLoading(false);
        console.log(e);
      }
    };

    const getFilter = async (body)=>{
      setAssetsLoading(true)
      let result;
      try{
        result=await(GetAssetFilter(body));
      }
      catch(e){
        console.log(e)
        setAssetsLoading(false)
      }
      if(result.success){
        setAssets([])
        result.assets.docs.map((data)=>{
          setAssets((assets) => assets.concat(data.asset))
        })
        setAssetsLoading(false)
        setTotalDocs(result.assets.totalDocs);
        setPage(result.assets.page)
      }
    }

    const filterassets = async (id,body) => {
      setAssetsLoading(true); 
      let result;
      try{
        result = await filterAsset(id,body,page)
      } catch (error) {
        console.log(error)
      }
      try {
        if(result.success) {
          setAssetsLoading(false);
          setAssets(result.assets.docs);
          setPage(result.assets.page)
          setTotalDocs(result.assets.totalDocs);
          console.log(result.success)
        }
      } catch (error) {
        setAssetsLoading(false);
        console.log(error)
      }
    }
    const clear=()=>{
      setPage(1)
      getAssetByCollection()
    }
    return (
        <div className={cn("container", styles.container)}>
      {loading ? (
        <div className={"loader"}>
          <Skeleton active avatar paragraph={{ rows: 3 }} />
        </div>
      ) : (
        <div>
          <Row style={{alignItems:"center"}}>
            <Col md={4} xs={24} sm={24} lg={4}>
              <div className={styles.logoContainer}>
                <img
                  src={collection?.logo}
                  className={styles.revealGif}
                  autoPlay
                  playsInline
                  loop
                  muted
                />
              </div>
            </Col>
            <Col md={20} xs={24} sm={24} lg={20}>
              <div className={styles.head}>
                <div>
                  <div className={styles.heading}>
                    <p className={styles.title}>{collection?.title}</p>
                    <MdVerified
                      className={styles.verifyIcon}
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                  <div className={styles.itemFlex}>
                  <div className={styles.creator}>
                    Created by &nbsp;
                    <div className={styles.mobCreator}>
                      <span className={styles.verifyIcon}>
                        {collection?.createdBy ? collection?.createdBy : collection?.title}
                      </span>
                      &nbsp;
                      <MdVerified className={styles.verifyIcon} />
                    </div>
                    <div className={styles.item2}>
                    
                  </div>
                  
                  {/* <div className={styles.item2}>
                    <div className={styles.item3}>Items</div>
                    {totalDocs}
                  </div> */}
                  </div>
                 
                  </div>
                  {collection?.tokenStandard !== "ERC-1155" &&
                  <div className={styles.Item}>
                  <div className={styles.item3}>Total NFTs</div>
                 <div className={styles.docs}>  {totalDocs} </div>  
                    </div>}
                  <div className="flex justify-center w-100 mb-4">
                    <div className="flex flex-col text-center w-50 items-center">
                      <p className={styles.description}>
                        {collection?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col md={4} xs={24} sm={24} lg={4}>
              {collection?.createdBy === "wowTalkies" &&
            <button
                    className={cn("button", styles.button)}
                    onClick={() => {
                      setItem(collection);
                      setVisibleModalBid(true);
                    }}
                  >
                    Mint Now
                  </button>}
            </Col> */}
          </Row>

          <Row className={styles.assets}>
            <Col md={4} lg={4} sm={24} xs={24}>
              <Filter1
               filter={filterassets} clear={clear}
               /> 
            </Col>
            <Col lg={20} sm={24} xs={24} md={20}>
              {assetsLoading ? (
                <Loader className={styles.assetLoader} />
              ) : assets.length < 1 ? (
                <div className={styles.emptyAssets}>No assets available</div>
              ) : (
                <div>
                  <Row>
                    {assets.map((asset, index) => (
                      <Col xs={24} sm={24} lg={6} md={6} key={index}>
                  <Link className={styles.link} to={asset?.tokenStandard === "ERC-1155" ? `/collectionDetail/items/${asset._id}` : `/item/collections/${asset._id}`}>
                        <div className={styles.card}>
                          {asset.imageUrl || asset.previewImage || asset?.attachments[0]?.url || asset.videoUrl ?
                          <>
                          {asset.imageUrl || asset.asset?.imageUrl || asset.previewImage ? (
                            <img
                              src={asset.imageUrl || asset.asset?.imageUrl || asset.previewImage}
                              className={styles.cardImage}
                              onError={(e)=>{e.target.onerror = null; e.target.src="https://wowtalkiesdevbucket.s3.ap-south-1.amazonaws.com/collections/no-image.png"}}
                            />
                          ) : (
                            <>
                            {asset?.attachments[0]?.url || asset.asset?.attachments[0]?.url?
                            <img
                              src={asset?.attachments[0]?.url || asset.asset?.attachments[0]?.url}
                              className={styles.cardImage}
                            /> :
                            <video
                              src={asset.videoUrl || asset.asset?.videoUrl}
                              className={styles.cardImage}
                              autoPlay loop muted
                            />}
                            </>
                          )}</>
                          :
                          <img src={noImg} className={styles.cardImage}/>}
                          <div className={styles.cardTitle}>
                          {/* {collection.title.length > 20 ? collection.title.slice(0, 20) + "...." : collection.title} */}

                            {/* {asset.displayName ? asset.displayName : asset.name} */}
                           
                            {asset.displayName.length > 20 ? asset.displayName.slice(0,20) + "...." : asset.displayName ? asset.displayName : asset.name }
                          
                          </div>

                          <hr className={styles.divider} />
                          {asset.tokenPrice > 0 && 
                          <div className={styles.price}>
                       

                            <img src={Polygon}  />

                            {/* <FaEthereum  /> */}
                            {parseFloat(asset.tokenPrice>=0 ? asset.tokenPrice : asset.asset?.tokenPrice.toFixed(3))}
                            <span className={styles.usd}>
                              {" "}
                              ({(usd * (asset.tokenPrice>=0 ? asset.tokenPrice : asset.asset?.tokenPrice)).toFixed(3)} USD)
                            </span>
                          </div>
}
                        </div>
                  </Link>
                      </Col>
                    ))}
                  </Row>
                  {/* <Pagenation
                    onChange={(page) => {
                      setPage(page);
                    }}
                    total={totalDocs}
                    current={page}
                    defaultPageSize={12}
                  /> */}
                  <div className={styles.pagination}>
                  <Pagination current={page} onChange={(page) => {setPage(page);}} total={totalDocs} defaultPageSize={12} />
                    </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
      <Modal
            visible={visibleModalBid}
            onClose={() => setVisibleModalBid(false)}
          >
            <Purchase item={collection} onClose={() => setVisibleModalBid(false)} />
          </Modal>
        </div>
    )
}

export default AllCollections;
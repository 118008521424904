import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import SellerPage from "./components/SellerPage";
import Home from "./screens/Home";
import Home2 from "./screens/Home2";
import Login from "./screens/Authentication/Login";
import Register from "./screens/Authentication/Register";
import ForgotPassword from "./screens/Authentication/ForgotPassword";
import UploadVariants from "./screens/UploadVariants";
import UploadDetails from "./screens/UploadDetails";
import EditAsset from "./screens/EditAsset";
import ConnectWallet from "./screens/ConnectWallet";
import Faq from "./screens/Faq";
import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item";
import SellerItem from "./screens/SellerItem";
import PageList from "./screens/PageList";
import SideNav from "./components/SideNav";
import { SnackbarProvider } from 'notistack'
import Nft from "./screens/Nft";
import Orders from "./screens/Order";
import SellerHome from './components/SellerHome'
import SellerAsset from './screens/SellerAsset'
import Collections from "./screens/Home/Collections";
import Collection from "./screens/Home/collection";
import { ethers } from 'ethers';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider as Web3Provider } from '@ethersproject/providers';
import AllCollections from "./screens/Collections";
import CollectionDetail from "./screens/collectionDetail";
import Mail from "./components/SupportMail";
import BuyMatic from "./screens/BuyMatic";
import ProfileDetail from "./screens/Profile/Detail";

function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}
function App(props) {
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={3} preventDuplicate={true} hideIconVariant anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
  }}>
    <Web3ReactProvider getLibrary={getLibrary}>
    <Router>
      <Switch>
        <Route
          exact
          path="/"
            render={(props) => (
            // <Page>
              <Home props={props} />
            // </Page>
          )}
        />
         <Route
          exact
          path="/Partners"
          render={() => (
            <Page>
              <Home2 />
            </Page>
          )}
        />
        <Route
          exact
          path="/upload-variants"
          render={() => (
            <Page>
              <UploadVariants />
            </Page>
          )}
        />
        <Route
          exact
          path="/upload-details"
          render={() => (
            <Page>
              <UploadDetails />
            </Page>
          )}
        />
        <Route
          exact
          path="/connect-wallet"
          render={() => (
            <Page>
              <ConnectWallet />
            </Page>
          )}
        />
        <Route
          exact
          path="/faq"
          render={() => (
            <Page>
              <Faq />
            </Page>
          )}
        />
        <Route
          exact
          path="/activity"
          render={() => (
            <Page>
              <Activity />
            </Page>
          )}
        />
        <Route
          exact
          path="/Explore"
          render={(props) => (
            <Page>
              <Collections  title="Explore"/>
            </Page>
          )}
        />
        <Route
          exact
          path="/search02"
          render={() => (
            <Page>
              <Search02 />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile/:uid"
          render={() => (
            <Page>
              <Profile />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile-edit"
          render={(props) => (
            <Page>
              <ProfileEdit props={props} />
            </Page>
          )}
        />
        <Route
          exact
          path="/item/:json/:id"
          render={() => (
            <Page>
              <Item props={props} />
            </Page>
          )}
        />
        <Route
          exact
          path="/pagelist"
          render={() => (
            <Page>
              <PageList />
            </Page>
          )}
        />
        <Route
          exact
          path="/register"
          render={(props) => (
              <Register props={props} />
          )}
        />
        <Route
          exact
          path="/login"
          render={(props) => (
              <Login props={props} />
          )}
        />
        <Route
          exact
          path="/forgot-password"
          render={() => (
              <ForgotPassword />
          )}
        />
        <Route
          exact
          path="/Seller-Home"
          render={(props) => (
            <SellerPage props={props}>
              <SideNav  history={props.history} />
              <SellerHome />
              </SellerPage>
          )}
        />
        <Route
          exact
          path="/Seller-Upload"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <UploadDetails />
              </SellerPage>
          )}
        />
                <Route
          exact
          path="/Seller-profile/:uid"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <Profile />
              </SellerPage>
          )}
        />
        <Route
          exact
          path="/Seller-items"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <Search01 />
              </SellerPage>
          )}
        />
        <Route
          exact
          path="/Seller-Nft"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <Nft history={props.history} />
              </SellerPage>
          )}
        />
         <Route
          exact
          path="/Seller-order"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <Orders history={props.history} />
              </SellerPage>
          )}
        />
            <Route
          exact
          path="/Seller-item/:id"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <SellerItem history={props.history} />
            </SellerPage>
          )}
        />
        <Route
          exact
          path="/Seller-Asset/:id"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <SellerAsset history={props.history} />
            </SellerPage>
          )}
        />
         <Route
          exact
          path="/Seller-item/:id/edit"
          render={(props) => (
            <SellerPage>
              <SideNav  history={props.history} />
              <EditAsset history={props.history} />
            </SellerPage>
          )}
        />
         <Route
          exact
          path="/collection"
          render={() => (
            <Page>
              <Collection />
            </Page>
          )}
        />
        <Route
          exact
          path="/collections/:id"
          render={() => (
            <Page>
              <AllCollections />
            </Page>
          )}
        />
         <Route
          exact
          path="/support"
          render={() => (
            <Page>
              <Mail/>
            </Page>
          )}
        />
        <Route
          exact
          path="/collectionDetail/:json/:id"
          render={(props) => (
            <Page>
              <CollectionDetail props={props}/>
            </Page>
          )}
        />
        <Route
          exact
          path="/detail/:id"
          render={(props) => (
            <Page>
              <ProfileDetail props={props}/>
            </Page>
          )}
        />
        <Route
          exact
          path="/buyMatic"
          render={(props) => (
            <Page>
              <BuyMatic/>
            </Page>
          )}
        />
      </Switch>
    </Router>
    </Web3ReactProvider>
    </SnackbarProvider>

  );
}

export default App;
